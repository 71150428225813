import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Grid from '../../atoms/Grid/Grid';
import NewsItem from '../NewsItem/NewsItem';

function NewsList({ query }) {
  
  let data = useStaticQuery(
    graphql
    `
    query{
      newsItems: allSanityNews(sort: {order: DESC, fields: postDate}, limit: 3) {
        nodes {
          image {
            asset {
             fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          headline
          postDate
          tags
          summary
          slug {
            current
          }
        }
      }
    }
    `
  );

  if(query) data = query;

  return(
    <>
      {data.newsItems.nodes.length > 0 ?
        <Grid columns={1} className="gap-4 mb-8 lg:grid-cols-3">
          {data.newsItems.nodes.map((newsItem) => (
            <NewsItem newsItem={newsItem} />
          ))}
        </Grid>
        :
        <div className="flex justify-center mb-8">
          <p className="font-lato text-2xl">No items found.</p>
        </div>
      }
    </>
  );
}

export default NewsList;