import React from 'react';
import Layout from '../components/layout';
import Container from '../components/atoms/Container/Container';
import FancyHeading from '../components/molecules/FancyHeading/FancyHeading';
import NewsList from '../components/organisms/NewsList/NewsList';
import Pagination from '../components/pagination';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import IEBanner from '../components/iebanner';

export default function news({ data }) {

  return (  
    <>
      <SEO title="News" />
      <IEBanner />
      <Layout>
        <Container className="bg-brand-light-grey">
          <div className="items-center">
              <FancyHeading center="true" darkBg="">News</FancyHeading>
              <NewsList query={data} />
              <Pagination pageSize={parseInt(process.env.GATSBY_NEWS_PER_PAGE)} totalCount={data.newsItems.totalCount} base="news"/>
            </div>
        </Container>
      </Layout>
    </>
  );
}

export const data = graphql
  `
  query {
    newsItems: allSanityNews(sort: {order: DESC, fields: postDate}) {
      totalCount
      nodes {
        image {
          asset {
          fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        headline
        postDate
        tags
        summary
        slug {
          current
        }
      }
    }
  }
  `
;