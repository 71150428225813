import React from 'react';
import Img from "gatsby-image"
import Link from '../../atoms/Link/Link';
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";

function NewsItem({newsItem}) {
    
    return(
        <div className="h-full flex flex-col">
            <Img 
                className="h-48" 
                fluid={newsItem.image.asset.fluid} 
            />
            <div className="flex-1 bg-white p-4">
                <div className="font-lato font-bold text-brand-red mb-4">{newsItem.headline}</div>
                <div className="mb-4">
                    <span className="font-lato text-brand-dark-grey">{newsItem.postDate}</span>
                    <span className="font-lato text-brand-dark-grey"> | </span>
                    {newsItem.tags.map((tag) => (
                        <>
                            <span className="font-lato text-brand-orange">{tag}</span>
                            <span className="font-lato text-brand-dark-grey"> | </span>
                        </>
                    ))} 
                    <span className="font-lato text-brand-dark-grey">Aigboje Aig-Imoukhuede</span>
                </div>
                <p className="font-lato text-brand-dark-grey mb-8">{newsItem.summary}</p>
                <Link className="font-lato font-bold text-brand-red" to={`/news/${newsItem.slug.current}`}><span>Read more</span><BsArrowRightShort className="inline-block ml-1 h-6 w-6" /></Link>
            </div>
        </div>
    );
}

export default NewsItem;