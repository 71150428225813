import React from 'react';
import Link from './atoms/Link/Link';

export default function Pagination({pageSize, totalCount, base}) {

    const totalPages = Math.ceil(totalCount / pageSize);

    return (
        <div className="w-1/2 mx-auto flex flex-nowrap justify-around py-1 items-center">
            {Array.from({length: totalPages}).map((_, index) => (
                <>
                    <Link to={`/${base}${index > 0 ? `/${index + 1}` : ""}`} className="font-lato text-brand font-brand-dark-grey hover:text-brand-red hover:underline" activeClassName="text-brand-red">{index + 1}</Link>
                    {index < totalPages - 1 ? <div className="bg-brand-lighter-grey w-px h-4"/> : <></>}
                </>
            ))}
        </div>
    );
}