import React from 'react';
import PropTypes from 'prop-types';

function Grid({ rows, columns, className, children }) {
  return (
    <div className={`grid grid-rows-${rows > 0  && rows < 7? rows : "none"} grid-cols-${columns > 0  && columns < 13? columns : "none"} ${className}`}>{children}</div>
  );
}


Grid.propTypes = {
    rows: PropTypes.number,
    columns: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Grid.defaultProps = {
    rows: 0,
    columns: 2,
    className: "",
};

export default Grid;